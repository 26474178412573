import React, { useContext , useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import shop from '../../../assets/images/shop.svg';
import order from '../../../assets/images/order.svg';
import setting from '../../../assets/images/settings.svg'
import vendor from '../../../assets/images/vendor.svg'
import shippingLabel from '../../../assets/images/shipping-label.svg';
import CommonContext from '../../../context/CommonContext';
export default function Sidebar() {
  const location = useLocation();
  const { getLabel, unprintLabelCount, pickupPendingCount, getOrderPendingCount, orderPendingCount } = useContext(CommonContext);
  const shopPattern = /^\/admin\/shops\/third-party\/([^/]+)\/([^/]+)$/;
  const subOrderViewPattern = /^\/admin\/(pre-)?orders\/view\/([^/]+)\/([^/]+)$/;
  const orderAllViewPattern = /^\/admin\/(pre-)?orders\/view\/([^/]+)\/(A)$/;
  const orderSapViewPattern = /^\/admin\/(pre-)?orders\/view\/([^/]+)\/(0)$/;
  const orderWooCommerceViewPattern = /^\/admin\/(pre-)?orders\/view\/([^/]+)\/(1)$/;
  const orderShopifyViewPattern = /^\/admin\/(pre-)?orders\/view\/([^/]+)\/(2)$/;
  const orderAmazonViewPattern = /^\/admin\/(pre-)?orders\/view\/([^/]+)\/(3)$/;
  const orderPickupViewPattern = /^\/admin\/pickup-orders\/view\/([^/]+)\/(P)$/;
  useEffect(()=>{
    getOrderPendingCount();
    // eslint-disable-next-line
  },[])
  return (
    <>
      <div className='body_container'>
        <aside className="leftside no-print" id="sidebar-menu">
          <div className="sidebar_inner sidebar-menu" id="sidebar-menu">
            <ul className="leftmenu menu">
              <li className={`leftmenu_handle menu-item ${(location.pathname === "/admin/orders" || orderAllViewPattern.test(location.pathname)) ? "active" : ""} ${location.pathname === "/admin/orders" || subOrderViewPattern.test(location.pathname) || location.pathname === "/admin/orders/strictly-auto-parts" || location.pathname === "/admin/orders/woocommerce" ? "active_sub" : "" || location.pathname === "/admin/orders/amazon" ? "active_sub" : "" || location.pathname === "/admin/orders/shopify" ? "active_sub" : ""}`}>
                <Link to="/admin/orders" >
                  <span className="menu-icon">
                    <img src={order} alt="order" />
                  </span>
                  <span className="menu-title-name">{getLabel('LBL_Orders')}</span>
                  {orderPendingCount > 0 && <span className='count-badge'>{orderPendingCount}</span>}
                </Link>

                <ul className="sub_menus">
                  <li className={` ${(location.pathname === "/admin/orders/strictly-auto-parts" || orderSapViewPattern.test(location.pathname)) ? "sub_active" : ""}`}>
                    <Link to="/admin/orders/strictly-auto-parts">
                      <span className="sub-menu-title-name menu-title-name">{getLabel('LBL_Strictly_Auto_Parts')}</span>
                    </Link>
                  </li>
                  <li className={` ${(location.pathname === "/admin/orders/woocommerce" || orderWooCommerceViewPattern.test(location.pathname)) ? "sub_active" : ""}`}>
                    <Link to="/admin/orders/woocommerce" >
                      <span className="sub-menu-title-name menu-title-name">{getLabel('LBL_WooCommerce')}</span>
                    </Link>
                  </li>
                  <li className={` ${(location.pathname === "/admin/orders/amazon" || orderAmazonViewPattern.test(location.pathname)) ? "sub_active" : ""}`}>
                    <Link to="/admin/orders/amazon" >
                      <span className="sub-menu-title-name menu-title-name">{getLabel('LBL_Amazon')}</span>
                    </Link>
                  </li>
                  <li className={` ${(location.pathname === "/admin/orders/shopify" || orderShopifyViewPattern.test(location.pathname)) ? "sub_active" : ""}`}>
                    <Link to="/admin/orders/shopify" >
                      <span className="sub-menu-title-name menu-title-name">{getLabel('LBL_Shopify')}</span>
                    </Link>
                  </li>
                </ul>

              </li>
              <li className={`leftmenu_handle menu-item ${location.pathname === "/admin/pickup-orders" || orderPickupViewPattern.test(location.pathname) ? "active" : ""}`}>
                <Link to="/admin/pickup-orders" >
                  <span className="menu-icon">
                    <img src={shop} alt="shop" />
                  </span>
                  <span className="menu-title-name">{getLabel('LBL_PICK_UP_ORDERS')}</span>
                  {pickupPendingCount > 0 && <span className='count-badge'>{pickupPendingCount}</span>}
                </Link>
              </li>
              <li className={`leftmenu_handle menu-item ${location.pathname === "/admin/imported-labels" ? "active" : ""}`}>
                <Link to="/admin/imported-labels" >
                  <span className="menu-icon">
                    <img src={shippingLabel} alt="logout_icon" />
                  </span>
                  <span className="menu-title-name">{getLabel('LBL_Imported_labels')}</span>
                  {unprintLabelCount > 0 && <span className='count-badge'>{unprintLabelCount}</span>}
                </Link>
              </li>
              <li className={`leftmenu_handle menu-item ${location.pathname === "/admin/shops" || shopPattern.test(location.pathname) ? "active" : ""}`}>
                <Link to="/admin/shops" >
                  <span className="menu-icon">
                    <img src={vendor} alt="shop" />
                  </span>
                  <span className="menu-title-name">{getLabel('LBL_Vendor')}</span>
                </Link>
              </li>

              <li className={`leftmenu_handle menu-item ${location.pathname === "/admin/settings" ? "active" : ""}`}>
                <Link to="/admin/settings" >
                  <span className="menu-icon">
                    <img src={setting} alt="logout_icon" />
                  </span>
                  <span className="menu-title-name">{getLabel('LBL_Settings')}</span>
                </Link>
              </li>
            </ul>
          </div>
        </aside>
        <Outlet />
      </div>
    </>
  )
}
