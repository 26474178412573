import React ,{useContext} from 'react'
import Modal from 'react-bootstrap/Modal';
import CommonContext from '../../../context/CommonContext';
import {changePickupStatus} from '../../../services/orders/index'
import { addProcessingLoader, removeProcessingLoader } from '../../../helpers/commonHelpers';
import { toast } from 'react-toastify';
export default function RemoveChannel(props) {
    const {show ,setShow , data, setData, loadData, filters} = props
    const {getLabel, constants, getOrderPendingCount} = useContext(CommonContext);
    const handleClose = () => {
        setShow(false);
        setData({
            ...data,
            order_ids: 0,
            order_status: 0,
            products: []
        })
    }

    const updatePickupStatus = async (id, status) => {
        const data = {
            'order_ids': [id],
            'order_op_ids': [],
            'order_status': status
        }
        addProcessingLoader();
        try {
            const msgRes = await changePickupStatus(data);
            removeProcessingLoader();
            if (msgRes.status === true) {
                toast.success(msgRes.message);
                getOrderPendingCount();
                loadData(filters);
                handleClose();
                return;
            }
            toast.error(msgRes.message)
        } catch (error) {
            removeProcessingLoader();
            toast.error(error);
        }
    }

  return (
    <>
      <Modal show={show} onHide={handleClose} centered className='location custom-modal pickup-modal'>
        <Modal.Header closeButton >
          <Modal.Title className='top-title'>{data.order_status === constants.ORDER_PICKUP_READY ? getLabel('LBL_Prepare_products_for_pick_up') : getLabel('LBL_Mark_order_as_picked_up?') }</Modal.Title>
        </Modal.Header>
        {
            data.order_status === constants.ORDER_PICKED_UP 
            ? 
                <>
                    <div className='text-center'>{getLabel('LBL_These_items_have_been_picked_up_by_the_customer_and_will_be-fulfilled.')}</div>
                    <div className='text-center pb-2'>{getLabel('LBL_A_pickup_confirmation_email_will_be_sent.')}</div>
                </>
            :
            ''
        }
        <Modal.Body>
                {data.products.map((product, index) => {
                return  <React.Fragment key={index}>
                        <div className="row popup-body-row">
                            <div className="col-md-2">
                                
                                <div className="item_img">
                                    <img src={product.images ? process.env.REACT_APP_IMAGE_BASE_URL+product.images[0].afile_physical_path : '/images/no_image.jpg'} alt="productImage" />
                                </div>
                            </div>
                            <div className="col-md-9 product-detail">
                                <div className="product-desc">
                                    <div>{`${product && product.op_brand_name} ${product && product.op_product_name}`}</div>
                                    
                                    <div>
                                    {product.op_selprod_code &&  product.op_selprod_code}
                                    </div>
                                    
                                    <div>{product.op_selprod_compatibility_text}</div>
                                    <div className="sku_css">{product.op_selprod_sku}</div>
                                </div>
                                <div className="qty">
                                    {product.op_qty - product.op_refund_qty - product.totalCancelQty}
                                </div>
                            </div>
                        </div>
                        <div className='divider'></div>
                    </React.Fragment>
                })
            }
            <div className='row pt-2'>
                <div className="col-lg-6 col-sm-6">
                    <button type="button" className="btn btn-outline-secondary" onClick={handleClose} /*onClick={() => {handleDelete(id); handleClose()}}*/>{getLabel('LBL_Cancel')}</button>
                </div>
                <div className="col-lg-6 col-sm-6">
                    <button type="button" className="btn btn-outline-secondary text-light pickup-btn" onClick={() => updatePickupStatus(data.order_ids,data.order_status)}>{data.order_status === constants.ORDER_PICKUP_READY ? getLabel('LBL_Send_pick_up_notification') : getLabel('LBL_Confirm') }</button>
                </div>
            </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
